import { ReactComponent as ChordiaLogoLight } from "../resources/images/chordia-light.svg";
import { ReactComponent as ChordiaLogoDark } from "../resources/images/chordia-dark.svg";
import {
  Button,
  Page,
  NavMenu,
  NavMenuButton,
  NavMenuButtonRow,
  NavMenuItem,
  NavMenuOptions,
} from "@tycholabs/armillary";
import { Link, Outlet, useLocation } from "react-router-dom";
import { LogIn } from "lucide-react";
import { useTheme } from "styled-components";

export const Root = () => {
  const theme = useTheme();
  const navItems: NavMenuItem[] = [
    {
      title: "Home",
      link: `/`,
    },
  ];

  const location = useLocation();
  return (
    <Page direction="column" style={{ height: "100dvh" }}>
      <NavMenu
        title="Chordia"
        icon={
          window.matchMedia("(prefers-color-scheme: dark)").matches ? (
            <ChordiaLogoDark
              fill={theme.colors.button.primary.background}
              color={theme.colors.button.primary.background}
              height="36px"
              width="36px"
            />
          ) : (
            <ChordiaLogoLight
              fill={theme.colors.button.primary.background}
              color={theme.colors.button.primary.background}
              height="36px"
              width="36px"
            />
          )
        }
      >
        <NavMenuButtonRow>
          {navItems.map((item) => (
            <Link style={{ textDecoration: "none" }} to={item.link}>
              <NavMenuButton
                title={item.title}
                icon={item.icon}
                selected={location.pathname === item.link}
              />
            </Link>
          ))}
        </NavMenuButtonRow>
        <NavMenuOptions>
          <Link
            style={{ textDecoration: "none" }}
            to={"https://chordia.app/login"}
          >
            <Button color="primary" width="100px" padding="0px">
              <LogIn size={18} />
              Sign In
            </Button>
          </Link>
        </NavMenuOptions>
      </NavMenu>
      <Outlet />
    </Page>
  );
};

export default Root;
