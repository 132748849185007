import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Landing from "./pages/Landing";
import "./resources/fonts.css";
import Root from "./pages/Root";
import { ThemeProvider } from "styled-components";
import { getArmillaryTheme } from "@tycholabs/armillary";
import { themeChordia } from "./resources/theme";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [{ path: "/", element: <Landing /> }],
  },
]);

function App() {
  return (
    <ThemeProvider theme={getArmillaryTheme(themeChordia)}>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default App;
