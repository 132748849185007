import axios from "axios";
import { useMutation } from "@tanstack/react-query";

const local = "http://localhost:5943";
const prod = "https://chordia.app/api/chordia";

const PostJoinMailing = async (email: string) => {
  return await axios
    .post(`${prod}/mailing`, {
      email,
    })
    .then((res) => {
      return res;
    });
};

export const UsePostJoinMailing = (email: string) => {
  const response = useMutation({
    mutationKey: [`${email}-PostJoinMailing`],
    mutationFn: () => PostJoinMailing(email),
  });
  if (response?.error?.message.slice(-3) === "401") {
    sessionStorage.clear();
    window.location.reload();
  }
  return response;
};
