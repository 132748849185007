import ChordiaBG from "../resources/images/Chordia-bg.png";
import { ReactComponent as ChordiaLogoLight } from "../resources/images/chordia-light.svg";
import { ReactComponent as ChordiaLogoDark } from "../resources/images/chordia-dark.svg";
import { useMobileStatus } from "../utils/IsMobileStatus";
import {
  Button,
  Content,
  ContentMain,
  Flex,
  Text,
  Card,
  FormField,
  TextInput,
  Heading,
  TransparencyCodes,
} from "@tycholabs/armillary";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { UsePostJoinMailing } from "../hooks/mailing";
import styled, { useTheme } from "styled-components";
import { CalendarCheck, Mails, UserRoundPlus } from "lucide-react";

interface LandingMailingListFormValues {
  email: string;
}

const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const StickyFooter = styled.div<{ color: string }>`
  position: relative;
  bottom: 0;
  z-index: 10;
  width: 100%;
  background: ${(props) => props.color};
  box-sizing: border-box;
`;

const GradientCardWrapper = styled(Card)`
  padding: 0px;
  width: 100%;
  border: 0px solid;
  color: ${(props) => props.theme.colors.text.secondary};
  &:hover {
    transition: 0.3s;
    color: ${(props) => props.theme.colors.text.primary};
    border: -3px solid;
    padding: 3px;
    background: linear-gradient(
        160deg,
        ${(props) =>
            props.theme.colors.button.primary.background + TransparencyCodes[6]}
          40%,
        ${(props) => props.theme.colors.button.primary.background}
      )
      right;
  }
`;

const GradientCard = styled(Card)`
  position: relative;
  height: 100%;
  padding: 20px;
  gap: 8px;
  &:hover {
    transition: 0.3s;
    padding: 17px;
    border-color: #00000000;
    background: ${(props) => props.theme.colors.card.secondary};
  }
`;

const BGDiv = styled.div`
  position: absolute;
  height: 100dvh;
  width: 100dvw;
  margin: 0px;
  overflow: hidden;
`;

const BGWrapper = styled.div`
  height: 100dvh;
  width: 100dvw;
  margin: 0px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-content: center;
`;

const BGImage = styled.image`
  border: 0px solid #fff;
  width: 100dvw;
  height: 100dvh;
  padding: 0px;
  background-image: url(${ChordiaBG});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 12px;
  opacity: 0.1;
`;

export const Landing = () => {
  const theme = useTheme();

  const isResizedMobile = useMobileStatus();
  const [isMobile, setIsMobile] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    setIsMobile(window.innerHeight > window.innerWidth);
  }, [isMounted]);

  useEffect(() => {
    setIsMobile(isResizedMobile);
  }, [isResizedMobile]);

  const {
    register,
    watch,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<LandingMailingListFormValues>({
    defaultValues: {
      email: "",
    },
  });

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [submittedSuccess, setSubmittedSuccess] = useState<boolean>(false);

  const joinMailing = UsePostJoinMailing(getValues("email"));

  const handleSubmitButtonClick = () => {
    setIsSubmitting(true);
    joinMailing.mutateAsync().then((res) => {
      if (res.data === "Success") {
        setSubmittedSuccess(true);
        setIsSubmitting(false);
      }
    });
  };

  return (
    <>
      {/* <WavesFrame>
        <WavesDiv> */}
      <BGDiv>
        <BGWrapper>
          <BGImage />
        </BGWrapper>
      </BGDiv>
      {/* </WavesDiv>
      </WavesFrame> */}
      <Content style={{ zIndex: 0, backgroundColor: "#00000000" }}>
        <ContentMain
          style={{ background: "#ffffff00", overflowY: "scroll" }}
          padding="0px"
        >
          <Flex
            direction="column"
            justify="start"
            align="center"
            gap="16px"
            style={{
              padding: "20px",
              minHeight: "80vh",
              boxSizing: "border-box",
            }}
          >
            <Flex direction="row" gap="8px" align="center" justify="center">
              {window.matchMedia("(prefers-color-scheme: dark)").matches ? (
                <ChordiaLogoDark
                  fill={theme.colors.button.primary.background}
                  color={theme.colors.button.primary.background}
                  height={"3rem"}
                  width={"3rem"}
                />
              ) : (
                <ChordiaLogoLight
                  fill={theme.colors.button.primary.background}
                  color={theme.colors.button.primary.background}
                  height={"3rem"}
                  width={"3rem"}
                />
              )}
              <Heading
                style={{
                  fontSize: "3rem",
                  color: theme.colors.text.link,
                }}
              >
                Chordia
              </Heading>
            </Flex>

            <Flex
              direction={isMobile ? "column" : "row"}
              justify="center"
              gap="16px"
              style={
                isMobile
                  ? {
                      alignItems: "center",
                      boxSizing: "border-box",
                    }
                  : { width: "75%", boxSizing: "border-box" }
              }
            >
              <GradientCardWrapper
                style={{
                  width: isMobile ? "95%" : "25%",
                }}
              >
                <GradientCard>
                  <Flex direction="row" gap="8px" align="center" justify="left">
                    <UserRoundPlus size={16} />
                    <Text weight="semiBold">Lessons</Text>
                  </Flex>
                  <Flex align="left" width="100%">
                    <Text color="secondary">
                      Keep track of your
                      <span style={{ color: theme.colors.text.primary }}>
                        {" "}
                        students{" "}
                      </span>
                      and
                      <span style={{ color: theme.colors.text.primary }}>
                        {" "}
                        lesson schedules{" "}
                      </span>
                      in one place
                    </Text>
                  </Flex>
                </GradientCard>
              </GradientCardWrapper>

              <GradientCardWrapper
                style={{
                  width: isMobile ? "95%" : "25%",
                }}
              >
                <GradientCard>
                  <Flex direction="row" gap="8px" align="center" justify="left">
                    <UserRoundPlus size={18} />
                    <Text weight="semiBold">Progress</Text>
                  </Flex>
                  <Flex align="left">
                    <Text color="secondary">
                      Track
                      <span style={{ color: theme.colors.text.primary }}>
                        {" "}
                        student progress{" "}
                      </span>
                      and
                      <span style={{ color: theme.colors.text.primary }}>
                        {" "}
                        pieces{" "}
                      </span>
                      through custom, business-defined
                      <span style={{ color: theme.colors.text.primary }}>
                        {" "}
                        curriculums{" "}
                      </span>
                    </Text>
                  </Flex>
                </GradientCard>
              </GradientCardWrapper>

              <GradientCardWrapper
                style={{
                  width: isMobile ? "95%" : "25%",
                }}
              >
                <GradientCard>
                  <Flex direction="row" gap="8px" align="center" justify="left">
                    <Mails size={18} />
                    <Text weight="semiBold">Reports</Text>
                  </Flex>
                  <Flex align="left">
                    <Text color="secondary">
                      Keep your students and parents
                      <span style={{ color: theme.colors.text.primary }}>
                        {" "}
                        informed and engaged{" "}
                      </span>
                      with automatic email-based
                      <span style={{ color: theme.colors.text.primary }}>
                        {" "}
                        reports & announcements{" "}
                      </span>
                    </Text>
                  </Flex>
                </GradientCard>
              </GradientCardWrapper>

              <GradientCardWrapper
                style={{
                  width: isMobile ? "95%" : "25%",
                }}
              >
                <GradientCard>
                  <Flex direction="row" gap="8px" align="center" justify="left">
                    <CalendarCheck size={18} />
                    <Text weight="semiBold">Events</Text>
                  </Flex>
                  <Flex align="left">
                    <Text color="secondary">
                      Save yourself time & hassle managing student
                      <span style={{ color: theme.colors.text.primary }}>
                        {" "}
                        festival, exam, and competition{" "}
                      </span>
                      requirements
                    </Text>
                  </Flex>
                </GradientCard>
              </GradientCardWrapper>
            </Flex>

            {!isMobile ? (
              <Card
                padding="20px"
                type="default"
                style={{
                  background: `${theme.colors.card.background + "EB"}`,
                  width: isMobile ? "95%" : "50%",
                }}
              >
                <Flex
                  direction="column"
                  gap="24px"
                  justify="center"
                  align="center"
                >
                  <Text size="large" weight="semibold">
                    Enter your email to join our mailing list.
                  </Text>
                  <form
                    noValidate
                    onSubmit={handleSubmit(() => {
                      try {
                        handleSubmitButtonClick();
                      } catch (e) {
                        console.log(e);
                      }
                    })}
                  >
                    <Flex direction="row" gap="8px" align="left">
                      <FormField
                        label="Email"
                        error={errors && errors.email?.message}
                      >
                        <Flex direction="row" gap="8px">
                          <TextInput
                            required
                            {...register("email", {
                              required: "Please enter a valid email.",
                              pattern: emailRegex,
                            })}
                            width={isMobile ? "" : "400px"}
                            placeholder="Enter email"
                            type="email"
                            value={watch("email")}
                          />
                          <Button
                            color="primary"
                            width="auto"
                            disabled={submittedSuccess}
                            loading={isSubmitting}
                            onClick={() => console.log("Submitted!")}
                          >
                            Register
                          </Button>
                        </Flex>
                      </FormField>
                    </Flex>
                  </form>
                  {submittedSuccess && (
                    <Text size="large" weight="bold">
                      Thanks for registering! More to come soon!
                    </Text>
                  )}
                </Flex>
              </Card>
            ) : (
              <Flex style={{ minHeight: "100px" }}></Flex>
            )}
          </Flex>
        </ContentMain>
      </Content>

      {isMobile && (
        <StickyFooter color={theme.colors.layout.background}>
          <Flex
            direction="column"
            gap="24px"
            justify="center"
            align="center"
            style={{
              padding: "16px 8px",
              boxSizing: "border-box",
            }}
          >
            <Text size="large" weight="semibold">
              Enter your email to join our mailing list.
            </Text>
            <form
              noValidate
              onSubmit={handleSubmit(() => {
                try {
                  handleSubmitButtonClick();
                } catch (e) {
                  console.log(e);
                }
              })}
            >
              <Flex direction="row" gap="8px" align="left">
                <FormField
                  label="Email"
                  error={errors && errors.email?.message}
                >
                  <Flex direction="row" gap="8px">
                    <TextInput
                      required
                      {...register("email", {
                        required: "Please enter a valid email.",
                        pattern: emailRegex,
                      })}
                      width={isMobile ? "" : "400px"}
                      placeholder="Enter email"
                      type="email"
                      value={watch("email")}
                    />
                    <Button
                      color="primary"
                      variant="alt"
                      width="auto"
                      disabled={submittedSuccess}
                      loading={isSubmitting}
                      onClick={() => console.log("Submitted!")}
                    >
                      Register
                    </Button>
                  </Flex>
                </FormField>
              </Flex>
            </form>
            {submittedSuccess && (
              <Text size="large" weight="bold">
                Thanks for registering! More to come soon!
              </Text>
            )}
          </Flex>
        </StickyFooter>
      )}
    </>
  );
};

export default Landing;
