import { ArmillaryColorTheme } from "@tycholabs/armillary";

export const themeChoral: ArmillaryColorTheme = {
  light: {
    background: "#ffffff",
    popover: "#ffffff",
    card: "#fffcfb",
    primary: "#f29559",
    focus: "#f29559",
    secondary: "#efd5ca",
    border: "#efd5ca",
    accent: "#59b5f2",
    subdued: "#fbd1bd",
    destructive: "#990000",
  },
  dark: {
    background: "#09090b",
    popover: "#09090b",
    card: "#1b1b1c",
    primary: "#eb956a",
    focus: "#eb956a",
    secondary: "#39393a",
    border: "#39393a",
    accent: "#59b5f2",
    subdued: "#4a4441",
    destructive: "#990000",
  },
};

export const themeChordia = themeChoral;
